import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import {
  getDropdownListMonths,
  getDropdownListYears,
} from "../../../_utils/mapTransactions";
import Pagination from "../../pagination";
import Selector from "../../selector";
import TransactionsTable from "./table";
import SearchBar from "../../searchBar";
import styles from "./upcoming.module.scss";
import useDebounce from "../../../_utils/debounce";
import { termRegex } from "../../../_utils/utils";
import moment from "moment";
import Loading from "../../loading";
import { exportToCSV } from "../../../_utils/exportToCSV";
import Button from "../../button";
import documentService from "../../../_services/document.service";
import toastService from "../../../_services/toast.service";
import { getTransactionsStats } from "../../../_services/transaction.service";
const FinancialInfoDashboard = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [stats, setStats] = useState({
    total: "0.00",
    successful: "0.00",
    pending: "0.00",
    refunded: "0.00",
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfTransactions, setNumberOfTransactions] = useState(0);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    transactionType: "",
    searchTerm: "",
    paymentType: "",
  });
  const debouncedSearchTerm = useDebounce(filterOptions.searchTerm, 500);
  const [sortConfig, setSortConfig] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [loadingCSV, setLoadingCSV] = useState(false);

  useEffect(() => {
    const arr = getDropdownListYears();
    setSelectedYear(arr[0].value);
    setYearsOptions(arr);
  }, []);
  useEffect(() => {
    setPageNumber(0);
  }, [
    debouncedSearchTerm,
    store.auth && store.auth.user,
    selectedMonth,
    filterOptions.status,
    filterOptions.transactionType,
    filterOptions.paymentType,
  ]);
  useEffect(() => {
    const fetchTransactionStats = async (month) => {
      let firstDay = month.getTime();
      let lastDay = new Date(
        month.getFullYear(),
        month.getMonth() + 1,
        1
      ).getTime();
      const filter = {
        createdAt: { $gte: firstDay, $lte: lastDay },
      };
      const response = await getTransactionsStats(filter);
      setStats(response);
      setLoadingStats(false);
    };
    if (store?.auth?.user?.regionId && selectedMonth) {
      setLoadingStats(true);
      fetchTransactionStats(selectedMonth);
    }
  }, [store.auth && store.auth.user, selectedMonth]);
  useEffect(() => {
    const getTransactions = async (
      regionId,
      pageNumber,
      month,
      filterOptions,
      term,
      sortConfig = { createdAt: -1 }
    ) => {
      let filter = [];
      if (filterOptions.status && filterOptions.status != "*") {
        filter.push({ status: filterOptions.status });
      }
      if (
        filterOptions?.transactionType &&
        filterOptions?.transactionType != "*"
      ) {
        filter.push({ transactionType: filterOptions.transactionType });
      }
      if (filterOptions?.paymentType && filterOptions?.paymentType != "*") {
        filter.push({ paymentType: filterOptions.paymentType });
      }
      if (regionId && regionId !== "*") {
        filter.push({
          $or: [{ "user.regionId": regionId }, { regiond_id: regionId }],
        });
      }
      if (term) {
        filter.push({
          $or: [
            { "user.name": { $regex: `${termRegex(term)}`, $options: "i" } },
            { "user.email": { $regex: `${termRegex(term)}`, $options: "i" } },
            {
              "user.fullName": { $regex: `${termRegex(term)}`, $options: "i" },
            },
          ],
        });
      }
      let firstDay = month.getTime();
      let lastDay = new Date(
        month.getFullYear(),
        month.getMonth() + 1,
        1
      ).getTime();
      filter.push({
        transactionType: { $nin: ["SUBSCRIPTION", "PAYOUT"] },
        amount: { $nin: [0, "NaN", "0", "0.00"] },
        createdAt: { $gte: firstDay, $lte: lastDay },
      });

      let data = await dispatch(
        mainActions.run("transactions", "transaction", "page", {
          filter,
          pageSize,
          pageNumber,
          sort: sortConfig,
        })
      );
      if (!data) data = { rows: [], total: 0 };
      setTransactions(data.rows);
      setNumberOfTransactions(data.total);
      setLoading(false);
    };
    if (store?.auth?.user?.regionId && selectedMonth) {
      setLoading(true);
      getTransactions(
        store.auth.user.regionId,
        pageNumber,
        selectedMonth,
        filterOptions,
        debouncedSearchTerm,
        sortConfig
      );
    }
  }, [
    store.auth && store.auth.user,
    debouncedSearchTerm,
    pageNumber,
    selectedMonth,
    filterOptions.status,
    filterOptions.transactionType,
    filterOptions.paymentType,
    sortConfig,
    refresh,
  ]);

  useEffect(() => {
    if (selectedYear) {
      const arr = getDropdownListMonths(selectedYear);
      setSelectedMonth(arr[0].value);
      setMonthsOptions(arr);
    }
  }, [selectedYear]);
  const onPageChange = (page) => {
    setPageNumber(page.selected);
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  const onChangeTerm = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFilterOptions((prevState) => ({ ...prevState, [name]: value }));
  };
  const changeSortConfig = (data) => {
    if (data) {
      const { key, direction } = data;
      setSortConfig({ [key]: direction === "ascending" ? 1 : -1 });
    }
  };
  const statusOptions = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Successful",
      value: "SUCCESSFULL",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Refunded",
      value: "REFUNDED",
    },
    {
      label: "Canceled",
      value: "CANCELED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ];
  const paymentTypes = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Card",
      value: "CARD",
    },
    {
      label: "Transact",
      value: "TRANSACT",
    },
    {
      label: "CBORD",
      value: "CBORD",
    },
  ];

  const transactionOptions = [
    {
      label: "All",
      value: "*",
    },
    {
      label: "Charged Container",
      value: "CHARGE_CONTAINER",
    },
    { label: "Pre Authorized Charge", value: "PRE_AUTH_PAYMENT_INTENT" },
    {
      label: "Refund",
      value: "REFUND",
    },
    { value: "RESTAURANT_INVOICE", label: "Monthly Invoice" },
    {
      label: "Subscription Rate",
      value: "SUBSCRIPTION_RATE",
    },
    {
      label: "Admin Charge",
      value: "ADMIN_CHARGE",
    },
    {
      label: "Penalty Charge",
      value: "CHARGE_LATE_FEES",
    },
    {
      label: "Per Use Charge",
      value: "PER_USE_CHARGE",
    },
    {
      label: "Per Use Report",
      value: "PER_USE_INVOICE",
    },
  ];
  const getCSVTransactionsData = async () => {
    let filter = {};
    if (filterOptions.status && filterOptions.status != "*") {
      filter.status = filterOptions.status;
    }
    if (
      filterOptions?.transactionType &&
      filterOptions?.transactionType != "*"
    ) {
      filter.transactionType = filterOptions.transactionType;
    }
    if (filterOptions?.paymentType && filterOptions?.paymentType != "*") {
      filter.paymentType = filterOptions.paymentType;
    }

    let firstDay = selectedMonth.getTime();
    let lastDay = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth() + 1,
      1
    ).getTime();
    filter["createdAt"] = { $gte: firstDay, $lte: lastDay };
    const response = await documentService.getTransactionsCSVData({
      filter,
    });

    return response;
  };
  const handleDownload = async () => {
    if (!store?.auth?.user?.regionId) return;
    setLoadingCSV(true);
    try {
      const response = await getCSVTransactionsData();

      const fileName =
        "Transactions-" +
        (store.auth.user.subregionName
          ? store.auth.user.subregionName + "-"
          : "") +
        moment(selectedMonth).format("MMM YYYY");
      exportToCSV(response, fileName);
    } catch (err) {
      toastService.show("warning", "Something went wrong. Please try again");
    }

    setLoadingCSV(false);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.column}>
          <span className={styles.spanTitle}>Charged</span>
          {loadingStats ? (
            <Loading height={30} width={30} />
          ) : (
            <span className={styles.spanText}>
              ${Number((stats?.charged || 0).toFixed(2)).toLocaleString()} CAD
            </span>
          )}
        </div>
        <div className={styles.column}>
          <span className={styles.spanTitle}>Uncaptured</span>
          {loadingStats ? (
            <Loading height={30} width={30} />
          ) : (
            <span className={styles.spanText}>
              ${Number((stats?.uncaptured || 0).toFixed(2)).toLocaleString()}{" "}
              CAD
            </span>
          )}
        </div>

        <div className={styles.column}>
          <span className={styles.spanTitle}>Refunded</span>
          {loadingStats ? (
            <Loading height={30} width={30} />
          ) : (
            <span className={styles.spanText}>
              {" "}
              ${Number((stats?.refunded || 0).toFixed(2)).toLocaleString()} CAD
            </span>
          )}
        </div>
        <div className={styles.column}>
          <span className={styles.spanTitle}>
            <span>Total</span>
            <span className={styles.date}>
              {moment(selectedMonth).format("MMMM,YYYY")}
            </span>
          </span>
          {loadingStats ? (
            <Loading height={24} width={24} />
          ) : (
            <span className={styles.spanText}>
              {" "}
              ${Number((stats?.total || 0).toFixed(2)).toLocaleString()} CAD
            </span>
          )}
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.searchAndDownload}>
          <div className={styles.searchBar}>
            <SearchBar
              customClass="restaurantSearch"
              placeholder={"Search user by name or email"}
              name="searchTerm"
              onChange={onChangeTerm}
              disabled={loading}
              value={filterOptions.searchTerm}
            />
          </div>
          <div className={styles.downloadButton}>
            <Button
              disabled={loadingCSV || loading}
              btnClass="btnWhiteBackground"
              label={"Download Transactions"}
              onClick={handleDownload}
            />
          </div>
        </div>

        <div className={styles.selects}>
          <div className={styles.selectType}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(
                transactionOptions,
                filterOptions.transactionType
              )}
              placeholder={"Transaction Type"}
              handleChangeSelect={(e) =>
                setFilterOptions((data) => ({
                  ...data,
                  transactionType: e.value,
                }))
              }
              disabled={loading}
              options={transactionOptions}
            />
          </div>
          <div className={styles.selectType}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(
                paymentTypes,
                filterOptions.paymentType
              )}
              placeholder={"Payment Type"}
              handleChangeSelect={(e) =>
                setFilterOptions((data) => ({
                  ...data,
                  paymentType: e.value,
                }))
              }
              disabled={loading}
              options={paymentTypes}
            />
          </div>
          <div className={styles.selectStatus}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(
                statusOptions,
                filterOptions.status
              )}
              placeholder={"Status"}
              handleChangeSelect={(e) =>
                setFilterOptions((data) => ({ ...data, status: e.value }))
              }
              disabled={loading}
              options={statusOptions}
            />
          </div>
          <div className={styles.selectDate}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(monthsOptions, selectedMonth)}
              handleChangeSelect={(e) => setSelectedMonth(e.value)}
              options={monthsOptions}
              disabled={loading}
            />
          </div>
          <div className={styles.selectDate}>
            <Selector
              label=""
              multiselect={false}
              selectClassname="selectGray"
              selectedOption={getSelectedValue(yearsOptions, selectedYear)}
              handleChangeSelect={(e) => setSelectedYear(e.value)}
              disabled={loading}
              options={yearsOptions}
            />
          </div>
        </div>
      </div>
      <TransactionsTable
        changeSortConfig={changeSortConfig}
        transactions={transactions}
        loadingData={loading}
        setRefresh={setRefresh}
      />

      <div className={styles.pagination}>
        <Pagination
          handlePageClick={onPageChange}
          pageCount={Math.ceil(numberOfTransactions / pageSize)}
          forcePage={pageNumber}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default FinancialInfoDashboard;
